import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";
import { Layout } from "../../components/shell";
import { Heading, Button, Input, Grid, Label, Link, Alert, Spinner, Text, Box, Flex } from 'theme-ui'
import { useForm } from 'react-hook-form'
import React, { useContext } from 'react'
import { SiteContext } from "../../root"
import { useAuth } from '@msc-insure/use-auth'

const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/

function PasswordForm() {
  const { error, authenticateEmailPassword } = useAuth()
  const { formState, register, handleSubmit } = useForm()
  const { errors } = formState

  const onSubmit = async ({ email, password}) => {
    await authenticateEmailPassword(email, password, { postLoginRoute: "/app" })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Box>
          <Label htmlFor="email">Email</Label>
          <Input
            {...register("email", { pattern: emailRegex })}
            aria-invalid={!!errors.email}
            placeholder="mail@beispiel.com"
            sx={{ fontSize: 3, width: '100%', maxWidth: '16em' }}
          />
          {errors.email && <Text>{errors.email.message}</Text>}
        </Box>
        <Box>
          <Label htmlFor="password">Passwort</Label>
          <Input
            type="password"
            {...register("password", { required: true })}
            aria-invalid={!!errors.password}
            sx={{ fontSize: 3, width: '100%', maxWidth: '16em' }}
          />
          {errors.password && (
            <Text>{errors.password.message}</Text>
          )}
        </Box>
      </Grid>
      <Box mt={4}>
        <Flex sx={{ alignItems: 'center' }}>
          <Button
            disabled={formState.isSubmitting}
            sx={{ fontSize: 3 }}
            variant="primary"
            type="submit"
          >
            Anmelden
          </Button>
          {formState.isSubmitting && <Spinner size={24} ml={3} />}
        </Flex>
      </Box>
      {error && <PasswordError />}
    </form>
  )
}

function PasswordError({ supportEmail }) {
  const { product } = useContext(SiteContext)

  return (
    <Alert mt={5} sx={{ maxWidth: '60ch' }} variant="warning">
      <Text>
        Leider ist ein Problem aufgetreten. Vergewissere Dich das Email und
        Passwort stimmen und versuche es vielleicht nochmal oder schreib uns auf{' '}
        <Link href={`mailto:${product.email}`}>{product.email}</Link>.
      </Text>
    </Alert>
  )
}

function LoginPage() {
  return (
    <Layout>
      <Box sx={{ mt: 5 }}>
        <Box variant="layout.container">
          <Flex sx={{ mb: 5, alignItems: "center" }}>
            <GatsbyLink style={{ display: "inline-flex" }} to="/app">
              <StaticImage
                layout="constrained"
                placeholder="tracedSVG"
                width={48}
                alt="Karl"
                src="../../images/icon-round.png"
              />
            </GatsbyLink>
            <Text
              sx={{
                pl: 3,
                fontSize: 4,
                lineHeight: 1,
              }}
            >
              Partner
            </Text>
          </Flex>
          <Box sx={{ maxWidth: "48ch" }}>
            <Heading variant="styles.h1" color="primary" mb={4}>
              Anmeldung zum Partner Portal
            </Heading>
            <Text sx={{ fontSize: [2, 3] }}>
              Damit es weiter geht musst Du Dich noch anmelden. Deine Email und Passwort genügt und Du bist schon drin.
            </Text>
          </Box>
          <Box my={4}>
            <PasswordForm />
          </Box>
          <Link as={GatsbyLink} to="/login">
            Passwort vergessen oder mit Email anmelden?
          </Link>
        </Box>
      </Box>
    </Layout>
  );
}

export default LoginPage;
